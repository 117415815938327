// src/components/ContactMe.js
import React from 'react';
import './ContactMe.css';
import { FaLinkedin, FaGithub, FaHandshake, FaEnvelope, FaFileAlt } from 'react-icons/fa'; // Import the resume icon

const ContactMe = () => {
  return (
    <section id="contact" className="contact-section">
      <h2 className="contact-header">Contact Me</h2>
      <div className="contact-buttons">
        <a href="https://www.linkedin.com/in/aidan-mcqueen-8631ab291/" target="_blank" rel="noopener noreferrer" className="contact-button">
          <FaLinkedin size={30} />
          <span>LinkedIn</span>
        </a>
        <a href="https://github.com/AMcQueen95" target="_blank" rel="noopener noreferrer" className="contact-button">
          <FaGithub size={30} />
          <span>GitHub</span>
        </a>
        <a href="https://uark.joinhandshake.com/profiles/44247550" target="_blank" rel="noopener noreferrer" className="contact-button">
          <FaHandshake size={30} />
          <span>Handshake</span>
        </a>
        <a href="mailto:amcqueenlol@gmail.com" className="contact-button">
          <FaEnvelope size={30} />
          <span>Email</span>
        </a>
      </div>
      <a 
        href="/Resume.pdf" 
        download="Aidan_McQueen_Resume.pdf" 
        className="contact-button"
      >
        <FaFileAlt size={30} />
        <span>Resume</span>
      </a>
    </section>
  );
};

export default ContactMe;
