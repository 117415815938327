// src/components/Projects.js
import React, { useState } from 'react';
import './Projects.css';
import { FaExternalLinkAlt } from 'react-icons/fa';

const projects = [
  {
    title: "Practically Magic Website",
    description: "Created two webpages for Practically Magic.",
    skills: ["HTML", "Webflow", "CSS"],
    link: "https://www.practicallymagic.co/",
  },
  {
    title: "Sports Recover App",
    description: "Created a sports recovery application for a hackathon hosted by the University of Arkansas.",
    skills: ["React Native", "Javascript", "Supabase"],
    link: "https://github.com/joshuaj0421/ZenAthlete",
  },
  {
    title: "This Website",
    description: "The website you are currently on.",
    skills: ["React", "CSS", "JavaScript"],
    link: "https://aidanmcqueen.com",
  }
];

const Projects = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleProjectClick = () => {
    if (isAnimating) return;

    setIsAnimating(true);

    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % projects.length);
      setIsAnimating(false);
    }, 800); // Duration of the shuffle animation (matches CSS)
  };

  return (
    <section id="projects" className="projects-section">
      <h2 className="projects-header">Projects</h2>
      <div className="projects-carousel">
        {projects.map((project, index) => {
          const isNextCard = (index === (currentIndex + 1) % projects.length);
          const zIndex = index === currentIndex ? 3 : isNextCard ? 2 : 1;
          return (
            <div
              key={index}
              className={`project-card ${index === currentIndex ? 'active' : ''} ${isAnimating && index === currentIndex ? 'shuffling' : ''}`}
              onClick={index === currentIndex && !isAnimating ? handleProjectClick : undefined}
              style={{ zIndex }}
            >
              <a
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className="project-link"
                onClick={(e) => e.stopPropagation()}
              >
                <FaExternalLinkAlt size={20} />
              </a>
              <div className="project-content">
                <h3 className="project-title">{project.title}</h3>
                <p className="project-description">{project.description}</p>
                <div className="project-skills">
                  {project.skills.map((skill, idx) => (
                    <span key={idx} className="skill-bubble">{skill}</span>
                  ))}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
