// src/components/Navbar.js
import React from 'react';
import { motion } from 'framer-motion';
import './Navbar.css'; // Import the CSS file

const Navbar = () => {
  const handleScroll = (e, sectionId) => {
    e.preventDefault();
    const element = document.querySelector(sectionId);
    const navbarHeight = document.querySelector('.navbar').offsetHeight+75; // Get navbar height
    const elementPosition = element.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

    window.scrollTo({
      top: elementPosition,
      behavior: 'smooth'
    });
  };

  return (
    <nav className="navbar">
      <ul className="nav-links">
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <a href="#about" onClick={(e) => handleScroll(e, '#about')}>About</a>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <a href="#experience" onClick={(e) => handleScroll(e, '#experience')}>Experience</a>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <a href="#projects" onClick={(e) => handleScroll(e, '#projects')}>Projects</a>
        </motion.li>
        <motion.li
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          transition={{ type: 'spring', stiffness: 300 }}
        >
          <a href="#contact" onClick={(e) => handleScroll(e, '#contact')}>Contact Me</a>
        </motion.li>
      </ul>
    </nav>
  );
};

export default Navbar;
