// src/components/About.js
import React from 'react';
import './About.css';
import profilePicture from './profile.jpg'; // Update this with the correct path

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-content">
        <div className="hero-img"></div>
        <div className="about-text">
          <h1>Aidan McQueen</h1>
          <p>Computer Science Student at the University of Arkansas</p>
        </div>
      </div>
    </section>
  );
};

export default About;
