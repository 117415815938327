// src/components/Experience.js
import React from 'react';
import './Experience.css';

const Experience = () => {
  const experiences = [
    {
      date: "Jan 2023 - Present",
      title: "Coding Instructor",
      company: "Vandergriff Elementary",
      description: "Taught 2nd - 4th grade students the basics of programming.",
      skills: ["Collaboration", "Problem Solving"],
      link: "https://www.xyzlearning.com"
    },
    {
      date: "May 2024 - July 2024",
      title: "Mobile App Developer Internship",
      company: "Trashy Studios",
      description: "Worked on filters and UI for an iOS photo editing application.",
      skills: ["Swift", "SwiftUI", "MetalPetal"],
      link: "https://www.trashystudio.co/"
    },
    {
      date: "Jan 2019 - May 2020",
      title: "Front End Developer",
      company: "DevelopIQ",
      description: "Created the front end for a website.",
      skills: ["JSX", "JavaScript", "CSS", "React"],
      link: "#"
    }
  ];

  return (
    <section id="experience" className="experience-section">
      <h2>Experience</h2>
      <div className="experience-container">
        {experiences.map((exp, index) => (
          <a key={index} href={exp.link} target="_blank" rel="noopener noreferrer" className="experience-box">
            <div className="experience-date">{exp.date}</div>
            <div className="experience-content">
              <h3 className="experience-title">{exp.title}</h3>
              <p className="experience-company">{exp.company}</p>
              <p className="experience-description">{exp.description}</p>
              <div className="experience-skills">
                {exp.skills.map((skill, i) => (
                  <span key={i} className="skill-oval">{skill}</span>
                ))}
              </div>
            </div>
          </a>
        ))}
      </div>
    </section>
  );
};

export default Experience;
