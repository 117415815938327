// src/components/GlowCursor.js
import React, { useState, useEffect } from 'react';
import './GlowCursor.css';

const GlowCursor = () => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div 
      className="glow-cursor" 
      style={{ left: `${mousePosition.x}px`, top: `${mousePosition.y}px` }}
    />
  );
};

export default GlowCursor;
