// src/components/Footer.js
import React from 'react';
import { motion } from 'framer-motion';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <motion.footer 
      className="footer"
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 100 }}
    >
      <p>&copy; 2024 Aidan McQueen. All rights reserved.</p>
    </motion.footer>
  );
};

export default Footer;
